import React from 'react';
import ReactDOM from 'react-dom/client';
import './constants/index.css';
import App from './App';
import {ChakraProvider} from "@chakra-ui/react";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <ChakraProvider>
          <React.StrictMode>
            <App />
          </React.StrictMode>
    </ChakraProvider>
);
