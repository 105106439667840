import {
    Box,
    Heading,
    Container,
    Text,
    Button,
    Stack, Image, Center, Modal, useDisclosure, ModalContent, ModalOverlay, ModalCloseButton,
} from '@chakra-ui/react'
import AllSocialMedia from "./components/social_media_buttons";
import React from "react";
import {call_to_action_blurb} from "../constants/constants";
import NewsLetterModal from "./components/news_letter_modal";
import logo from "../assets/logoWB.png";

export default function CallToActionWithAnnotation() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const finalRef = React.useRef(null)
    return (
        <>
            <head>
                <title>MiniQCrafts</title>
                <meta name="description" content="MiniQCrafts is a small business that sells handmade crafts." />
            </head>
            <Container maxW={'3xl'}>
                <Stack
                    as={Box}
                    textAlign={'center'}
                    spacing={{base: 8, md: 14}}
                    py={{base: 20, md: 36}}>
                    <Heading
                        fontWeight={600}
                        fontSize={{base: '2xl', sm: '4xl', md: '6xl'}}
                        lineHeight={'110%'}>
                        Welcome to MiniQCrafts <br/>
                        <Text as={'span'} color={'purple.400'}>
                            Full Site Coming Soon!
                        </Text>
                    </Heading>
                    <Center>
                        <Image src={logo} w='25vh'/>
                    </Center>
                    <Text color={'gray.500'}>
                        {call_to_action_blurb}
                    </Text>
                    <Stack
                        direction={'column'}
                        spacing={3}
                        align={'center'}
                        alignSelf={'center'}
                        position={'relative'}>
                        <Button
                            colorScheme={'green'}
                            bg={'purple.400'}
                            rounded={'full'}
                            px={6}
                            _hover={{
                                bg: 'purple.500',
                            }}
                            onClick={onOpen}
                            >
                            Sign up for our Newsletter
                        </Button>
                        <AllSocialMedia/>
                        <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose} size={'2xl'}>
                        <ModalOverlay />
                                <ModalContent h='80vh' w='75vw'>
                                    <ModalCloseButton />
                                    <NewsLetterModal/>
                                </ModalContent>
                        </Modal>                                                                 
                    </Stack>
                </Stack>
            </Container>
        </>
    )
}