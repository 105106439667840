'use client'

import { FaInstagram, FaEtsy, FaTiktok } from 'react-icons/fa'
import { Button, Center, Stack, Text } from '@chakra-ui/react'

export default function AllSocialMedia() {
    return (
        <Center p={8}>
            <Stack spacing={2} align={'center'} maxW={'md'} w={'full'}>
                <Button w={'full'} colorScheme={'purple'} rightIcon={<FaEtsy/>} onClick={() => {
                    window.location.href = 'https://www.etsy.com/shop/miniqcrafts/?etsrc=sdt'
                }}>
                    <Center>
                        <Text>Shop on our Etsy</Text>
                    </Center>
                </Button>
                <Button w={'full'} colorScheme={'purple'} rightIcon={<FaInstagram />} onClick={() => {
                    window.location.href = 'https://www.instagram.com/miniqcrafts/'
                }}>
                    <Center>
                        <Text>Follow us on Instagram</Text>
                    </Center>
                </Button>
                <Button w={'full'} colorScheme={'purple'} rightIcon={<FaTiktok />} onClick={() => {
                    window.location.href = 'https://www.tiktok.com/@miniqcrafts'
                }}>
                    <Center>
                        <Text>Follow us on TikTok</Text>
                    </Center>
                </Button>
            </Stack>
        </Center>
    )
}