import React from 'react';
import './constants/App.css';
import CallToActionWithAnnotation from "./pages/landing_page";

function App() {
  return (
        <CallToActionWithAnnotation/>
  );
}

export default App;
